import * as React from "react"
import { graphql } from "gatsby"
import { Layout, Start } from "../components"

class MainPage extends React.Component {

  state = {
    value: "",
  }

  handleInput = (e) => {
    this.setState({ value: e })
  }

  handleSearch = () => {
    const { location } = this.props;
    if (typeof window !== 'undefined') {
      const url = new URL(`${location.origin}/recherche/`);
      url.searchParams.set('t', this.state.value.trim());
      window.location.href = url;
    }
  }

  render() {
    return (
      <Layout>
        <div>
          <Start 
            handleSearch={this.handleSearch}
            handleInput={this.handleInput}
            featured={this.props.data.news.nodes}
            jdm={this.props.data.jdm}
          />
        </div>
      </Layout>
    );
  }
}

export default MainPage;
export const articleQuery = graphql`
  query articlesList {
    news: allPrismicNews(
      sort: {order: DESC, fields: first_publication_date}
      filter: {data: {startpage: {eq: true}, archive: {eq: false}, status: {eq: true}}}
    ) {
      nodes {
        uid
        data {
          startpage
          title {
            text
          }
          publicdate
          image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    jdm: file(relativePath: {eq: "Visuel_30ansJazzdeMars_A4.jpg"}) {
      publicURL
    }
  }
`